<template lang='pug'>
.radio-form
  el-radio-group.identity-radio-group(v-model="currentValue")
    el-radio.identity-radio(
      v-for="(option, index) in options"
      :key="`radio-${index}`"
      :label="option.id"
      @dblclick.native="$emit('switchUser', currentValue)") {{option.identificationValue}}
</template>

<script>
/**
 * @name 用户身份切换表单
 */

export default {
  name: 'SwitchIdentityForm',
  components: {
  },
  props: {
    value: {
      type: [Number, String],
      default: ''
    },
    options: {
      type: Array,
      default: () => ([])
    }
  },
  computed: {
    currentValue: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  data () {
    return {
    }
  },
  methods: {
  }
}
</script>

<style lang='sass' scoped>
.radio-form
  width: 100%
  height: 255px
  overflow: auto
  .identity-radio-group
    width: 100%
    .identity-radio
      display: flex
      align-items: center
      height: 75px
      padding: 0 20px
      background-color: #f5f6f8
      margin: 0 0 10px 0!important
/deep/.identity-radio
  .el-radio__label
    width: 100%
    color: #555
    word-break: normal
    word-wrap: break-word
    white-space: pre-wrap
</style>
