<template lang="pug">
el-container#app(direction='vertical')
  TopBar
  el-container#app-container(style="height: calc(100% - 60px)")
    SideBar(
        :menus='menus'
        :menuTitleConfig="menuTitleConfig")
    el-main#app-main(:style="mainStyle")
      el-container(direction='vertical')
        .tagsViewContainer
          TagsView.tagsview
        el-main.viewRouterContainer
          keep-alive(:include="include")
            router-view(
              ref="routerView"
              :key='$route.path')
</template>

<script>
import { mapState } from 'vuex'
import TopBar from '@/views/App/TopBar/index.vue'
import SideBar from '@/views/App/SlideBar'
import TagsView from './TagsView/index.vue'
import { getUserInfo } from '@/api/auth.js'

export default {
  name: 'AppFrame',
  components: {
    TopBar,
    SideBar,
    TagsView
  },
  data () {
    return {
      keepAliveInstance: null
    }
  },
  computed: {
    ...mapState({
      menus: ({ menu }) => menu.authMenu,
      visitedViews: ({ tagsView }) => tagsView.visitedViews
    }),
    include () {
      const names = this.visitedViews.filter(i => i.title !== 'not found').map(i => {
        // 去掉路由上?后面那段参数
        const path1 = i.path.split('?')[0].trim()
        const paramsNum = Object.keys(i.params).length
        const path1Arr = path1.split('/')
        const path2Arr = path1Arr.slice(0, path1Arr.length - paramsNum)
        const name = path2Arr.reverse()[0]
        const str = name[0]
        // 把第一个斜杠去掉,然后再把首字母转大写
        return name.replace(str, str.toUpperCase())
      })
      return [...new Set(names)]
    },
    mainStyle () {
      return {
        width: `calc( 100% - ${this.$store.state.menu.width} )`
      }
    },
    keys () {
      return this.keepAliveInstance && this.keepAliveInstance.keys
    }
  },
  methods: {
    // 循环设置keepAliveInstance,获取到keepAliveInstance时停下
    loopSetKeepAliveInstance (time) {
      if (time >= 10) {
        console.error('获取keepAlive缓存实例失败')
        return
      }
      if (!this.$refs.routerView) {
        setTimeout(() => {
          this.loopSetKeepAliveInstance(time + 1)
        }, 500)
      } else {
        this.keepAliveInstance = this.$refs.routerView.$vnode.parent.componentInstance
      }
    },
    handleRemoveCache (key) {
      /**
       * @param {Object} cache:类数组对象，存放缓冲的路由组件，键值对形，key为路由路径，value为路由组件Vnode
       * @param {Array} keys:数组，存放缓冲的路由组件的key,即路由路径或patientKey
       */
      const { cache, keys } = this.keepAliveInstance
      if (Object.prototype.hasOwnProperty.call(cache, key)) {
        cache[key].componentInstance.$destroy()
        this.$nextTick(() => {
          const keyIndex = keys.indexOf(key)
          if (keyIndex !== -1) {
            keys.splice(keyIndex, 1)
            this.$set(this.keepAliveInstance, 'keys', keys)
          }
          cache[key] && delete cache[key]
        })
      }
    },
    isActive (route) {
      return route.path === this.$route.path
    },
    toLastView (visitedViews, view) {
      const latestView = visitedViews.slice(-1)[0]
      if (latestView) {
        this.$router.push(latestView.fullPath)
      } else {
        // now the default is to redirect to the home page if there is no tags-view,
        // you can adjust it according to your needs.
        if (view.name === 'Dashboard') {
          // to reload home page
          // must be changed when home page changed
          this.$router.replace({ path: '/redirect' + view.fullPath })
        } else {
          this.$router.push('/')
        }
      }
    },
    handDelView (view) {
      this.$store.dispatch('tagsView/delView', view).then(({ visitedViews }) => {
        if (this.isActive(view)) {
          this.toLastView(visitedViews, view)
        } else {
          sessionStorage.setItem('PAGE_TAGS', JSON.stringify(this.visitedViews))
        }
      })
      this.handleRemoveCache(view.path)
    }
  },
  created () {
    localStorage.token && !this.menus.length && getUserInfo()
  },
  mounted () {
    window.globalEvent.on('delView', this.handDelView)
    this.$nextTick(() => {
      this.loopSetKeepAliveInstance(1)
    })
  }
}

</script>
<style lang="sass" scoped>
#app-main
  padding: 0
  background-color: $--content-backgroud
  #app-container
    height: calc( 100% - 48px )
    overflow: auto
  .viewRouterContainer
    background-color: #fff
    box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.15)
    margin: 8px
    padding: 0
</style>
