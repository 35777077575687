<template lang="pug">
#wf-aside(
  :style="style"
  :is-collapse="isCollapse")
  #slide-main
    AppMenu(
      v-bind="$attrs"
      ref="menu"
      :default-active="defaultActive"
      :menus="treeMenu"
      :collapse="isCollapse")
  #slide-footer
    MenuFooter(
      v-bind="menuFooter"
      :collapse="isCollapse"
      @toggleCollapse="toggleCollapse")
</template>

<script>
import MenuSearch from './MenuSearch'
import AppMenu from '../AppMenu'
import MenuFooter from './MenuFooter'
import cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'SlideBar',
  components: {
    MenuSearch,
    AppMenu,
    MenuFooter
  },
  props: {
    menus: {
      type: Array,
      default: () => ([])
    },
    searchPlaceholder: {
      type: String,
      default: '请输入关键字查询'
    },
    menuFooter: {
      type: Object,
      default: () => ({
        collapseIcon: 'el-icon-s-fold',
        spreadIcon: 'el-icon-s-unfold',
        iconWidth: '20px'
      })
    }
  },
  data () {
    return {
      defaultOpeneds: [],
      // 菜单树
      treeMenu: cloneDeep(this.menus)
    }
  },
  watch: {
    menus: {
      immediate: true,
      handler (menus) {
        this.treeMenu = cloneDeep(menus)
      }
    }
  },
  computed: {
    defaultActive () {
      const visitedViews = this.$store.state.tagsView.visitedViews
      const currentView = visitedViews.find(view => view.path === this.$route.fullPath)
      return currentView ? currentView.path : ''
    },
    style () {
      return {
        width: this.$store.state.menu.width
      }
    },
    // 侧边导航栏是否开启折叠模式；false关闭，true开启
    isCollapse () {
      return this.$store.state.menu.isCollapse
    }
  },
  filters: {
  },
  methods: {
    // 控制是否开启折叠模式
    toggleCollapse () {
      this.$store.commit('menu/TOGGLE_COLLAPSE', !this.isCollapse)
      this.$store.commit('menu/TOGGLE_WIDTH', !this.isCollapse ? '184px' : '50px')
    }
  }
}

</script>
<style lang="sass" scoped>
@import 'src/assets/css/var.sass'
#wf-aside
  z-index: $--zindex-low
  height: 100%
  transition: all .2s
  background: #364760
  opacity: 1
  #slide-head
    height: 76px
    .head-label
      line-height: 20px
      font-size: 14px
      font-weight: 500
      padding: 10px 16px
      color: #ffffff
      white-space: nowrap
      overflow: hidden
      .head-icon
        margin-right: 5px
  #slide-main
    height: calc( 100% - 50px )
    // height: 100%
    ::v-deep
      .wf-menu-title
        width: calc( 100% - 35px )
      .el-menu-item, .el-submenu__title
        min-width: 0
        padding: 0
  #slide-footer
    z-index: $--zindex-low
    height: 50px
    color: rgba(255,255,255,0.65)
    border-top: 1px solid
    border-top-color: rgba(0,0,0,0.30)
    background: #364760
#wf-aside[is-collapse]
  .head-label
    color: #a6a6a6
    padding: 10px 13px !important
    label
      display: none
  #slide-main
    ::v-deep
      .wf-icon+.menu-title-label
        display: none
      .el-menu-item,.el-submenu__title
        padding-left: 16px !important
        .wf-menu-title
          width: 25px
        .el-icon-arrow-down
          display: none
</style>
