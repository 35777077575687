<template  lang="pug">
el-header#app-header(height="48px")
  .headerMain
    AppLogo
    el-divider(direction="vertical")
    router-link.cursor.router-button(
      tag="span"
      active-class=""
      :key="home"
      :to="{ path: '/home' }") 首页
    //- AppMenu(
    //-   ref="topbarMenu"
    //-   mode="horizontal"
    //-   textColor="#FFFFFFA6"
    //-   activeTextColor="#FFFFFF"
    //-   backgroundColor="#222F3F"
    //-   :router="false"
    //-   :collapse="false"
    //-   :default-active="activeModule"
    //-   :menus="treeModuleMenus"
    //-   @select="handleSelect")
  UserAvatar(
    :userIdentity="userIdentityName"
    :userName="userName"
    :buttonList="buttonList")
  el-dialog(
    title="修改密码"
    :visible.sync="dialogVisible"
    width="40%"
    append-to-body
    :before-close="handleClose")
    DataForm(
      v-if="dialogVisible"
      v-model="formData"
      ref="form"
      type="row"
      label-position="top"
      :formFields="formFields"
      @onChange="handleChange")
    ButtonGroup(
      slot="footer"
      :buttonList="formButtonList")
  IdentityDialog(
    v-model="identityDialogVisable"
    :userIdentity.sync="userIdentity"
    @switchConfirm="handleSwitchConfirm")
</template>

<script>
import AppLogo from '@/views/App/AppLogo.vue'
import cloneDeep from 'lodash/cloneDeep'
import AppMenu from '@/views/App/AppMenu'
import UserAvatar from './UserAvatar'
import IdentityDialog from '@/views/Login/components/IdentityDialog'
import { getUserInfo } from '@/api/auth'

let samePassword = true

export default {
  name: 'TopBar',
  components: {
    AppLogo,
    AppMenu,
    UserAvatar,
    IdentityDialog
  },
  props: {
    moduleMenus: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    // 用户名
    userName () {
      return this.$store.state.user.userName
    },
    // 用户身份
    userIdentityName () {
      return this.$store.state.user.userIdentity
    },
    // 当前用户身份
    identifications () {
      return this.$store.state.menu.identifications
    }
  },
  data () {
    return {
      // 当前选中的模块菜单
      activeModule: '',
      // 模块菜单
      treeModuleMenus: cloneDeep(this.moduleMenus),
      showUserInfo: false,
      buttonList: [
        // {
        //   label: '切换身份',
        //   icon: 'el-icon-sort',
        //   func: () => {
        //     if (!this.identifications.length) {
        //       this.$message.warning('当前用户无可切换的身份')
        //     } else {
        //       this.identityDialogVisable = true
        //     }
        //   }
        // },
        {
          label: '修改密码',
          icon: 'el-icon-edit-outline',
          func: () => {
            this.dialogVisible = true
          }
        }, {
          label: '退出',
          icon: 'el-icon-switch-button',
          func: this.logout
        }],
      dialogVisible: false,
      formData: {},
      formFields: [{
        name: 'oldPassword',
        label: '旧密码',
        form: {
          tag: 'input',
          inputType: 'password',
          rules: [
            { required: true, message: '必填' }
          ]
        }
      },
      {
        name: 'newPassword',
        label: '新密码',
        form: {
          tag: 'input',
          inputType: 'password',
          rules: [
            { required: true, message: '必填' },
            {
              type: 'regexp',
              pattern: /^.*(?=.{6,})(?=.*\d)(?=.*[a-zA-Z]).*$/,
              message: '密码为字母加数字，最少六位'
            }
          ]
        }
      },
      {
        name: 'repeatPassword',
        label: '重复输入新密码',
        form: {
          tag: 'input',
          inputType: 'password',
          rules: [
            { required: true, message: '必填' },
            {
              validator (rule, value, callback, data) {
                const errorMsg = '密码不一致，请重新输入'
                if (!samePassword) callback(errorMsg)
                else callback()
              }
            }
          ]
        }
      }],
      formButtonList: [{
        name: 'confirm',
        label: '提交',
        type: 'primary',
        func: this.handleChangePassword
      }, {
        name: 'close',
        label: '关闭',
        func: this.handleClose
      }],
      identityDialogVisable: false,
      userIdentity: ''
    }
  },
  methods: {
    init () {
      if (this.treeModuleMenus.length) {
        this.activeModule = this.treeModuleMenus[0].url
        this.$emit('getModuleSelect', this.treeModuleMenus[0])
      }
    },
    // 模块菜单选中事件
    handleSelect (index, url) {
      this.activeModule = url[0]
      const cur = this.moduleMenus.find(item => url.includes(item.url))
      this.$emit('getModuleSelect', cur)
    },
    logout () {
      this.$get({ url: '/wfbase/logout' })
      this.$router.push('/loginout')
      sessionStorage.clear()
    },
    handleClose () {
      this.$refs.form.$refs.dataform.clearValidate()
      samePassword = true
      this.formData = {}
      this.dialogVisible = false
    },
    handleChange ({ values }) {
      const { newPassword, repeatPassword } = values
      samePassword = newPassword === repeatPassword
    },
    async handleChangePassword () {
      try {
        await this.$refs.form.$refs.dataform.validate()
        const res = await this.$put({
          url: '/wfbase/rbac/user/changePassword',
          data: {
            oldPassword: this.$md5(this.formData.oldPassword),
            newPassword: this.$md5(this.formData.newPassword)
          }
        })
        if (!res) return
        this.$message.success('修改密码成功，请重新登录！')
        this.handleClose()
        this.logout()
        return 'success'
      } catch (error) {
        return error
      }
    },
    async handleSwitchConfirm (userIdentity) {
      const loading = this.$loading({ fullscreen: true })
      try {
        const result = await this.$put({
          url: `/wfbase/rbac/identification/switch/${userIdentity}`
        })
        if (!result) {
          loading.close()
          return
        }
        this.$store.commit('menu/SET_CURRENT_IDENTITY_ID', userIdentity)
        await getUserInfo(this.routerToHome)
        loading.close()
        this.identityDialogVisable = false
        return
      } catch (error) {
        loading.close()
        return error
      }
    },
    routerToHome ({ authMenus }) {
      this.$message.success('用户身份切换成功！')
      const path = authMenus[0]?.children?.[0]?.url
      this.$route.path !== path && this.$router.push(path || '/')
      this.$store.dispatch('tagsView/delOthersViews', { path })
    }
  },
  mounted () {
    this.init()
  }
}
</script>

<style lang="sass" scoped>
@import 'src/assets/css/var.sass'
#app-header
  width: 100%
  display: flex
  align-items: center
  justify-content: space-between
  background-color: #222F3F
  font-size: 14px
  color: rgba(255,255,255,0.65)
  z-index: $--zindex-middle
  padding-left: 0px
  .router-button
    color: #fff
    font-size: 16px
  .headerMain
    display: flex
    align-items: center
    height: 100%
    ::v-deep
      .el-divider
        margin: 0 10px
        background-color: rgba(0,0,0,0.80)
      .el-menu-item
        padding: 0 !important
      .el-menu-item+.el-menu-item
        padding-left: 20px !important
</style>
