<template lang="pug">
.menu-footer.full-box
  .footer-text(v-show="!collapse")
    span 技术支持:
    span {{ phoneNumber }}
  .slide-collaspe-button(
    @click="handelClick"
    :is-collapse="collapse")
    i(
      :width="iconWidth"
      :height="iconWidth"
      :class="collapse ? spreadIcon : collapseIcon")
</template>

<script>
export default {
  name: 'MenuFooter',
  props: {
    collapse: {
      type: Boolean,
      default: false
    },
    // 折叠菜单的icon
    collapseIcon: {
      type: String,
      default: 'el-icon-s-fold'
    },
    // 展开菜单的icon
    spreadIcon: {
      type: String,
      default: 'el-icon-s-unfold'
    },
    iconWidth: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      phoneNumber: '-'
    }
  },
  methods: {
    handelClick () {
      this.$emit('toggleCollapse')
    }
  }
}

</script>
<style lang="sass" scoped>
.menu-footer
  display: flex
  flex-direction: row
  align-items: center
  .footer-text
    flex: 1
    font-size: 14px
    padding: 0 4px
    line-height: unset
    span
      display: inline-block
      margin-right: 4px
  .slide-collaspe-button
    width: 49px
    line-height: 50px
    height: 100%
    text-align: center
    cursor: pointer
  .slide-collaspe-button[is-collapse]
    background-color: #0a111a
</style>
