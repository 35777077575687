<template lang="pug">
el-dialog.dialog(
  v-if="dialogVisable"
  width="30%"
  center
  append-to-body
  :visible.sync="dialogVisable")
  .dialog-title(slot="title")
    .title-box
      .text-label
        span.main-title 身份选择
        span.sub-title 双击选中确认
  SwitchIdentityForm(
    v-model="identityId"
    :options="identifiOptions"
    @switchUser="handleConfirm")
  ButtonGroup(
    slot="footer"
    :buttonList="dialogBtns")
</template>

<script>
import SwitchIdentityForm from './SwitchIdentityForm.vue'

export default {
  name: 'IdentityDialog',
  components: { SwitchIdentityForm },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    userIdentity: {
      type: [Number, String],
      default: null
    }
  },
  computed: {
    dialogVisable: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    identityId: {
      get () {
        return this.userIdentity
      },
      set (value) {
        this.$emit('update:userIdentity', value)
      }
    },
    currentIdentityId () {
      return this.$store.state.menu.currentIdentityId
    },
    identifications () {
      return this.$store.state.menu.identifications
    },
    identifiOptions () {
      return this.identifiOptionsFormat(this.identifications)
    }
  },
  data () {
    return {
      dialogBtns: [{
        name: 'confirm',
        label: '确认',
        type: 'primary',
        func: () => this.handleConfirm(this.userIdentity)
      }, {
        name: 'cancel',
        label: '取消',
        func: () => {
          this.dialogVisable = false
        }
      }],
      relations: {}
    }
  },
  methods: {
    // 获取角色部门，职级的relation，用于切换身份是时拿到角色，部门的label
    getUserRelation () {
      this.$relation(['orgs']).then(res => {
        if (!res) return
        this.relations = res.data
      })
    },
    // 切换角色选项的格式转换
    identifiOptionsFormat (options = []) {
      const { orgs } = this.relations
      return options.map(option => {
        // 部门
        const orgName = option.orgPath.map(value => {
          const item = orgs ? orgs.find(org => org.value === value) : null
          return item ? item.label : ''
        }).join('>')
        option.identificationValue = `${orgName}`
        return option
      })
    },
    handleConfirm (userIdentity) {
      if (this.currentIdentityId === userIdentity) {
        this.$message.warning('选中身份与当前登陆身份相同')
      } else {
        this.$emit('switchConfirm', userIdentity)
        this.dialogVisable = false
      }
    }
  },
  created () {
    this.getUserRelation()
  }
}
</script>

<style lang="sass" scoped>
.dialog
  .dialog-title
    .title-box
      display: flex
      align-items: center
      border-left: 3px solid #4285F5
      padding-left: 10px
      height: 21px
      .main-title
        font-size: 18px!important
        color: #555
      .sub-title
        font-size: 14px!important
        font-weight: 400!important
        color: #999
        margin-left: 4px
</style>
