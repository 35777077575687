<template lang="pug">
el-submenu(
  v-if="children && children.length > 0"
  :index="url"
  :title="label")
  template(slot="title")
    .wf-menu-title
      i.menu-icon.wf-icon(
        v-if="icon"
        :class="icon")
      span.menu-title-label {{label}}
  MenuItem(
    v-for="menu in children"
    :key="menu.url"
    v-bind="menu"
    :children="menu.children"
    :collapse="collapse")
el-menu-item(
  v-else
  :index="url"
  :title="label"
  @click="handleRouterTo")
  template(slot="title")
    i.menu-icon.wf-icon(
      v-if="icon"
      :class="icon")
    span.wf-menu-title.menu-title-label(
      v-show="!collapse") {{ label }}
    span.wf-menu-title.menu-title-label(
      v-if="collapse && !children") {{ label.split('')[0] }}
</template>

<script>
export default {
  name: 'MenuItem',
  props: {
    label: {
      type: String,
      default: 'no-name'
    },
    url: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: 'gailan'
    },
    children: {
      type: Array
    },
    collapse: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
    }
  },
  methods: {
    handleRouterTo () {
      this.$route.path !== this.url && this.$router.push({ path: this.url })
    }
  }
}

</script>
<style lang="sass" scoped>
.wf-menu-title
  display: flex
  align-items: center
  .wf-icon+.menu-title-label
    margin-left: 8px
  .menu-icon
    color: #fff
    font-size: 16px
</style>
