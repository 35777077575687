<template lang="pug">
#app-logo
  img(:src="logo" height="26px")
  span.text-title {{ title }}
</template>

<script>
import logo from '@/assets/imgs/logoWhite.png'

export default {
  name: 'AppLogo',
  props: {
    title: {
      type: String,
      default: '大运河(北京)供水营收系统'
    }
  },
  data () {
    return {
      logo
    }
  }
}
</script>

<style lang="sass" scoped>
#app-logo
  display: flex
  align-items: center
  height: 100%
  font-size: 22px
  color: #fff
  text-align: center
  padding-left: 8px
  .text-title
    padding: 0 8px
</style>
